import React from "react";
import "./loading.css";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  return (
    <div className="loading bg-sepaWhite" style={{ color: "#1977BC" }}>
      <CircularProgress color="inherit" size={80} />
    </div>
  );
};

export default Loading;
