// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBi3P4-XmM_1MCZpKXKK9YVlztAW7atoY0",
  authDomain: "blocktickets-d98dd.firebaseapp.com",
  projectId: "blocktickets-d98dd",
  storageBucket: "blocktickets-d98dd.appspot.com",
  messagingSenderId: "662270286467",
  appId: "1:662270286467:web:1cd2c0dcfa9bf18ce919b6",
  measurementId: "G-RPH8E2HG5L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const fbProvider = new FacebookAuthProvider();
const analytics = getAnalytics(app);

export { app, auth, googleProvider, fbProvider, analytics };
