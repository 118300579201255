import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Cashfree
import { cashfreeProd } from "cashfree-dropjs";
import { cashfreeSandbox } from "cashfree-dropjs";
import { toast } from "react-toastify";
import "./TicketBox.css";
import axios from "axios";
import {
  TicketServices,
  setAuthToken,
  StripeServices,
} from "../../api/supplier";
import { ticketLockedIncrement } from "../../api/api-client";
import MobileNumber from "../../Modals/MobileNumber/MobileNumber";
import getSymbolFromCurrency from "currency-symbol-map";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Stripe/StripeCheckoutForm";
import FullLoading from "../../Loading/FullLoading";
import { updateDetails } from "../../api/api-client"
import {formatPhoneNumberIntl} from 'react-phone-number-input';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { contractAddress, NFTContractAbi, switchChain } from "../../utils/web3/web3";
import Web3 from "web3";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payments = ({
  selectedTickets,
  setShowFullLoading,
  setRenderComponent,
  setPayMessage,
  setMessageModal,
  setMessageModalDesc,
  isLogin,
  setSignIn,
  currency,
  event,
  promoCodeInfo,
  getValues,
  ip,
  breakupPrices,
  eventId,
}) => {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [userPhoneNumber, setPhoneNumber] = useState();
  const [clientSecret, setClientSecret] = useState("");
  const [click, setClick] = useState(false);
  const [token, setToken] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [maticUSD, setMaticUSD] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect( () =>{
    setPhoneNumber(JSON.parse(sessionStorage.getItem("user-data"))?.phoneNumber);
  }, [])
  const newcbs = (data) => {
    const selectedTickets = JSON.parse(localStorage.getItem("selectedTickets"));
    let finalPrices = [];
    for (let i = 0; i < selectedTickets.length; i++) {
      finalPrices.push(selectedTickets[i]?.tax_prices?.finalPrice);
    }
    if (
      (data.order && data.order.status == "PAID") ||
      data.order.status == "ACTIVE"
    ) {
      setShowFullLoading(true);
      setPayMessage(
        "Your transaction is successful. Please wait while we book your ticket"
      );
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_URL}/payment/cashfree-verify`,
        headers: {
          Authorization: `Bearer ${isLogin}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          orderId: data.order.orderId,
        }),
      };
      axios(config)
        .then(function (response) {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/ticket/book-ticket`,
              JSON.stringify({
                orderId: data.order.orderId,
                method: "cashfree",
                userId: JSON.parse(sessionStorage.getItem("user-data"))?._id,
                couponCode: promoCodeInfo.code,
                finalPrices: finalPrices,
              }),
              {
                headers: {
                  Authorization: `Bearer ${isLogin}`,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((val) => {
              setRenderComponent(false);
              setShowFullLoading(false);
              navigate(`/dashboard/v2?events=${btoa(eventId)}`);
              toast.success("You booking is confirmed");
            })
            .catch(async (err) => {
              await TicketServices.ticketLockedDecrement({ selectedTickets });
              setRenderComponent(false);
              setShowFullLoading(false);
              setMessageModal(true);
              setMessageModalDesc(
                "Could not book tickets. Contact support for refund details if any amount was debited from your account."
              );
            });
        })
        .catch(async function (error) {
          await TicketServices.ticketLockedDecrement({ selectedTickets });
          setRenderComponent(false);
          setShowFullLoading(false);
          setMessageModal(true);
          setMessageModalDesc(
            "Tickets not Booked. Contact support for refund details"
          );
        });
    } else {
      setRenderComponent(false);
      setMessageModal(true);
      setShowFullLoading(false);
      setMessageModalDesc("Payment Error 110: Payment Failed...");
    }
  };

  const signIn = () => {
    setSignIn(true)
    setClick(true)
  }

  useEffect(() => {
    setToken(sessionStorage.getItem("token"))
  })

  useEffect(() => {
    if (click && token) {
      setPhoneNumber(JSON.parse(sessionStorage.getItem("user-data"))?.phoneNumber);
      onContinue()
    }
  }, [click, token])
  
  const cbf = async (data) => {
    const selectedTickets = JSON.parse(localStorage.getItem("selectedTickets"));
    setRenderComponent(false);
    setMessageModal(true);
    setShowFullLoading(false);
    setMessageModalDesc("Payment Error 109: Payment Failed...");
    await TicketServices.ticketLockedDecrement({ selectedTickets });
  };
  const renderDropin = (resultOrderToken) => {
    if (resultOrderToken === "") {
      // alert("Order Token is empty");
      return;
    }
    setRenderComponent(true);

    let parent = document.getElementById("drop_in_container");
    parent.innerHTML = "";
    let cashfree;

    if (process.env.REACT_APP_CHAIN == "production") {
      cashfree = new cashfreeProd.Cashfree();
    } else {
      cashfree = new cashfreeSandbox.Cashfree();
      // toast.info("Test Environment!!");
    }

    cashfree.initialiseDropin(parent, {
      orderToken: resultOrderToken || "",
      onSuccess: newcbs,
      onFailure: cbf,
      components: ["order-details", "card", "netbanking", "upi"],
      style: {
        //to be replaced by the desired values
        backgroundColor: "#ffffff",
        color: "#042469",
        fontFamily: "Lato",
        fontSize: "14px",
        errorColor: "#ff0000",
        theme: "light", //(or dark)
        minHeight: "400px",
      },
    });
  };
  const createPay = async (e) => {
    e.preventDefault();
    setAuthToken();
    setShow(false);
    localStorage.setItem("selectedTickets", JSON.stringify(selectedTickets));
    console.log("createPay ", selectedTickets);
    // const headers = {
    //   method: "POST",
    //   Authorization: `Bearer ${isLogin}`,
    //   "Content-Type": "application/json",
    // };
    setPayMessage(
      "Taking you to the payment gateway, Please do not press back button"
    );
    setShowFullLoading(true);
    if (typeof userPhoneNumber === "undefined" ) {
      setMessageModal(true);
      setShowFullLoading(false);
      setMessageModalDesc("Please enter your 10 digit phone number");
      return;
    }
    const temp = formatPhoneNumberIntl(userPhoneNumber);
    let i = 0;
    while(temp[i] != " "){
      i++;
    }
    console.log(i);
    let count = 0, tempNo = 0;
    for(let j = i+1; j<temp.length; ++j){
      if(temp[j] !== " "){
        tempNo *= 10;
        tempNo += Number(temp[j]);
      }
    }
    // if(count !== 10){
    //   setMessageModal(true);
    //   setShowFullLoading(false);
    //   setMessageModalDesc("Please enter your 10 digit phone number");
    //   return;
    // }
    const update = await updateDetails({ phoneNumber: userPhoneNumber.toString() }, isLogin);
    console.log(update?.data?.data)
    sessionStorage.setItem("user-data", JSON.stringify(update?.data?.data?.updatedUser));
    const userInfo = {
      email: update?.data?.data?.updatedUser?.email,
      userId: update?.data?.data?.updatedUser._id,
      phone: tempNo.toString(),
    };
    setAuthToken();
    const increaseCounter = await ticketLockedIncrement(
      {
        selectedTickets,
      },
      sessionStorage.getItem("token")
    );
    if (increaseCounter && increaseCounter.status === 200) {
    
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/payment/cashfree-get-order-token`,
          {
            selectedTickets,
            userInfo,
            breakupPrices,
            eventId,
            couponCode: promoCodeInfo?.valid ? getValues("promoCode") : null,
          },
          {
            headers: {
              Authorization: `Bearer ${isLogin}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const { status } = response;
          setShowFullLoading(false);
          return response.data;
        })
        .then((result) => {
          renderDropin(result.orderToken);
        })
        .catch(async (err) => {
          console.log(err);
          await TicketServices.ticketLockedDecrement({ selectedTickets });
          setShowFullLoading(false);
          setMessageModal(true);
          setMessageModalDesc(
            err?.response?.data?.msg
              ? err?.response?.data?.msg
              : "ERR CGOT:Your Ticket was not booked some error occurred, Please Try again"
          );
        });
    } else {
      await TicketServices.ticketLockedDecrement({ selectedTickets });
      setShowFullLoading(false);
      setMessageModal(true);
      setMessageModalDesc(
        "ERR INC_C:Your Ticket was not booked some error occurred, Please Try again"
      );
    }
  };
  const onContinue = async () => {
    setOpen(true)
    // let check = true;
    // console.log(promoCodeInfo?.ticketType);
    // if(typeof promoCodeInfo?.ticketType === "undefined"){
    //   check = false;
    // }else{
    //   for(let i = 0; i< selectedTickets.length; ++i){
    //     if(selectedTickets[i]._id === promoCodeInfo.ticketType && selectedTickets[i].quantity >0){
    //       check = false;
    //       break;
    //     }
    //   }
    // }
    //   console.log(check);
    // if(check)
    //   return toast.error(`Promo code is valid for ${promoCodeInfo.ticketName} only`);
    // if (selectedTickets.length === 0)
    //   return toast.error("Please selct a ticket to continue...");
    // else setShow(true);
    // createPaymentIntent();
  };

  const onFiat = async () => {
    setAuthToken();
    let check = true;
    console.log(promoCodeInfo?.ticketType);
    setOpen(false)
    if(typeof promoCodeInfo?.ticketType === "undefined"){
      check = false;
    }else{
      for(let i = 0; i< selectedTickets.length; ++i){
        if(selectedTickets[i]._id === promoCodeInfo.ticketType && selectedTickets[i].quantity >0){
          check = false;
          break;
        }
      }
    }
      console.log(check);
    if(check)
      return toast.error(`Promo code is valid for ${promoCodeInfo.ticketName} only`);
    if (selectedTickets.length === 0)
      return toast.error("Please selct a ticket to continue...");
    else setShow(true);
    createPaymentIntent();
  }

  useEffect(() => {
    document.body.scrollTop = 0;
    axios
      .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=inr`
      )
      .then((res) => {
        setMaticUSD(res.data["matic-network"].inr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const buyNft = async (nftId, ticketId, nftRefId, price) => {
    setIsLoading(true);;

    if (!window.ethereum) {
      setIsLoading(false);
      window.alert("Please install metamask first or use card payment...");
      return;
    }
    if (!sessionStorage.getItem("token")) {
      setIsLoading(false);
      return;
    }

    window.ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then(async (res) => {
        await switchChain()
        const web3 = new Web3(Web3.givenProvider);
        const walletAccount = await web3.eth.getAccounts();
        const nftContract = new web3.eth.Contract(
          NFTContractAbi,
          contractAddress
        );

        const tickets = []
        let amount = 0
        let maticAmount = 0

        console.log(selectedTickets,"tickets")

        selectedTickets.map((value,index)=>{
          for(let i=0;i<value.quantity;i++){
            tickets.push(value.ticketNftId)
            amount = amount + Number(value.price)
            maticAmount = maticAmount + Number(value.maticPrice)
            selectedTickets[index].minterAddress = walletAccount[0]
          }
        })

        console.log(selectedTickets,"selected")

        const response = await nftContract.methods
          .buyTicket(tickets,walletAccount[0])
          .send({
            from: walletAccount[0],
            value: maticAmount.toString(),
          })

        const nftIndex = []
        const transactionHash = response.transactionHash;
        if(Array.isArray(response.events.Minted)){
          response.events.Minted.map((value)=>{
            nftIndex.push(value.returnValues.id)
          })
        }
        else{
          nftIndex.push(response.events.Minted.returnValues.id)
        }
        await TicketServices.web3TicketPurchase({
          userId: JSON.parse(sessionStorage.getItem("user-data"))?._id,
          couponCode: promoCodeInfo.code,
          amount : amount,
          tickets : selectedTickets,
          breakupPrices: breakupPrices,
          nftIndex,
          transactionHash
        })
        setIsLoading(false);
        navigate(`/dashboard/v2?events=${btoa(eventId)}`);
        toast.success("You booking is confirmed");
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setMessageModal(true);
        setMessageModalDesc(err.message);
      });

    // setBuyLoading(false);
  };
  const createPaymentIntent = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const res = await StripeServices.paymentIntent({
        selectedTickets,
        breakupPrices,
        userId: JSON.parse(sessionStorage.getItem("user-data"))?.userId,
        userEmail: JSON.parse(sessionStorage.getItem("user-data"))?.email,
        couponCode: promoCodeInfo?.valid ? getValues("promoCode") : null,
      });
      // console.log("res: ", res.data);
      setClientSecret(res.data.clientSecret);
      setIsLoading(false);
    } catch (err) {
      console.log(err?.response?.message);
      toast.error(err?.response?.message || "STRIPE: Internal Server Error");
      setIsLoading(false);
    }
  };

  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        colorPrimary: "#fa6400",
      },
    },
  };
  return (
    <>
      {isLoading && <FullLoading />}
      {new Date(event?.startDate) < new Date() ? (
        <button className='bg-sepaWhite rounded-lg col-span-full py-2 w-full text-sepaBlue  border-2 border-sepaBlue text-[1.4rem] lg:text-[2.1rem] font-bold '>
          Available at the venue
        </button>

      ) : !isLogin ? (
        <button
          className='bg-sepaWhite rounded-lg col-span-full py-2 w-full text-sepaBlue  border-2 border-sepaBlue text-[1.4rem] lg:text-[2.1rem] font-bold '
          onClick={signIn}
        >
          {currency === "AED" ? currency : getSymbolFromCurrency(currency)}{" "}
          {breakupPrices["finalPrice"]
            ? Math.ceil(breakupPrices["finalPrice"]?.toFixed(2))
            : 0}{" "}
          Payable - Continue
        </button>

      ) : (
        <button
          onClick={onContinue}
          className='bg-sepaWhite  rounded-lg col-span-full py-2 w-full text-sepaBlue  border-2 border-sepaBlue text-[2.4rem] font-bold '
        >
          {currency === "AED" ? currency : getSymbolFromCurrency(currency)}{" "}
          {breakupPrices["finalPrice"]
            ? Math.ceil(breakupPrices["finalPrice"]?.toFixed(2))
            : 0}{" "}
          - Continue{" "}
        </button>
      )}
      <div
        className={
          clientSecret
            ? "stripe-container-show flex-col"
            : "stripe-container-hide"
        }
      >
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              setClientSecret={setClientSecret}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Elements>
        )}
      </div>

      <MobileNumber
        show={show}
        setShow={setShow}
        onContinue={
          // ip?.country_name === "India" ? createPay : createPaymentIntent
          createPay
        }
        setPhoneNumber={setPhoneNumber}
        phoneNumber={userPhoneNumber}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          className: "!bg-sepaWhite !text-sepaBlack"
        }}
      >
        <DialogTitle id="alert-dialog-title" className="font-medium flex gap-8">
          {"Choose your method of purchase"}
          <button onClick={() => setOpen(false)} className="bg-white h-8 w-8 flex items-center justify-center rounded-full ">
            <CloseRoundedIcon />
          </button>
        </DialogTitle>
        <DialogContent className="flex flex-col gap-4">
          <button onClick={buyNft} className="bg-white shadow hover:text-lg transition-all rounded-lg px-8 h-16 border-gray-400 border">
            Crypto Currency
          </button>
          <button onClick={onFiat} className="bg-white shadow hover:text-lg transition-all rounded-lg px-8 h-16 border-gray-400 border">
            Fiat Currency
          </button>
        </DialogContent>
      </Dialog>
    </>
  );
};
// Taking you to the payment gateway, Please do not press back button
export default Payments;
